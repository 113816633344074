<template>
    <div class="storeGoods">
        <div class="storeGoodsHeader">
            <span></span>
            <router-link :to="{name:'studentExamingOnlineShopVisualDesignPageSetUpGood', query: {moduleId: $route.query.moduleId, id: $route.query.id}}">
                <el-button type="primary" size="medium">发布商品</el-button>
            </router-link>
        </div>
        <el-table :data="goodsData" style="margin-top:20px;width: 100%;flex: 1;" height="1%"
                  class="customTable"
                  size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="image_url" label="商品信息" align="left">
                <template slot-scope="scope">
                    <div class="goodsInfo">
                        <img :src="scope.row.image_url" alt="">
                        <span class="text-overflow-2">{{scope.row.goods_name}}</span>
                    </div>
                </template>
            </el-table-column>
<!--                <el-table-column prop="class_name" label="行业分类" align="center"></el-table-column>-->
            <el-table-column prop="goods_price" label="价格" align="center">
                <template slot-scope="scope">
                    <span>{{Number(scope.row.goods_price).toFixed(2)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="goods_zhekou_price" label="折扣价" align="center">
                <template slot-scope="scope">
                    <span>{{Number(scope.row.goods_zhekou_price).toFixed(2)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="editShop(scope.row.id)">修改商品</el-button>
                    <router-link tag="a" target="_blank" :to="{name:'stuGoodDetail',query:{id: scope.row.id}}" style="margin-left: 20px">
                        <span class="goods-preview">查看</span>
                    </router-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                v-if="goodsData.length>10"
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    import { stuOpStuGoodsList } from '@/utils/apis.js'
    export default {
        name: "StoreGoods",
        data(){
            return {
                goodsData:[],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.getListGoods()
        },
        methods: {
            getListGoods(){
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum
                };
                stuOpStuGoodsList(param).then(res => {
                    this.goodsData = res.data.list;
                    this.adminPages.total = res.data.total;
                }).catch(err => {})
            },
            editShop(id){
                this.$router.push({
                    path:'/student/examing/onlineShopVisualDesign/pageSetUpGood',
                    query: {
                        editID:id,
                        moduleId: this.$route.query.moduleId,
                        id: this.$route.query.id
                    }
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getListGoods()
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .storeGoods{
        /*height: calc(100vh - 160px);*/
        height: 100%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .storeGoodsHeader{
            display: flex;
            justify-content: space-between;
            .issueBtn{
                background: #FD4446;
                color: #FFFFFF;
                font-size: 14px;
                border: none;
                border-radius:2px;
            }
        }
    }
    .goodsInfo{
        display: flex;
        align-items: center;
        /*justify-content: center;*/
        /*padding-left: 60px;*/
        img{
            width: 58px;
            height: 58px;
        }
        span{
            margin-left: 10px;
        }
    }
</style>